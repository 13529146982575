//@import "../../components/Common/Comments/Comments";
@import '../../Screens/Auth/Auth';

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../fonts/subset-SourceSansPro-SemiBold.eot');
  src: url('../../fonts/subset-SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/subset-SourceSansPro-SemiBold.woff2') format('woff2'),
    url('../../fonts/subset-SourceSansPro-SemiBold.woff') format('woff'),
    url('../../fonts/subset-SourceSansPro-SemiBold.ttf') format('truetype'),
    url('../../fonts/subset-SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../fonts/subset-SourceSansPro-Regular.eot');
  src: url('../../fonts/subset-SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/subset-SourceSansPro-Regular.woff2') format('woff2'),
    url('../../fonts/subset-SourceSansPro-Regular.woff') format('woff'),
    url('../../fonts/subset-SourceSansPro-Regular.ttf') format('truetype'),
    url('../../fonts/subset-SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
}

#root,
.App {
  height: 100%;

  // лоадер располагается в центре контента
  // используется на главной пока загружаем профиль
  // или в любом другом месте где надо
  .full-screen-spin {
    margin-top: 45vh;
  }
}

.mb-3 {
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mr-3 {
  margin-right: 1rem;
}

.ml-3 {
  margin-left: 1rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.hidden {
  display: none;
}

.title {
  margin: 30px 0 15px;
}

.d-flex {
  display: flex;
}

.text-sm {
  font-size: @font-size-base - 2px;
}

/**
 * Button styles
 */
.btn-wrap button {
  margin-right: 20px;
  margin-bottom: 20px;
}

.ant-btn.green:not(:disabled) {
  color: #28a745;
  border-color: #28a745;
}

.ant-btn.green:not(:disabled):hover,
.ant-btn.green:not(:disabled):focus,
.ant-btn-primary.green:not(:disabled) {
  color: #fff;
  background: #28a745;
  border-color: #28a745;
}

.ant-btn-primary.green:not(:disabled):hover,
.ant-btn-primary.green:not(:disabled):focus {
  color: #fff;
  background: #218838;
  border-color: #218838;
}

.ant-btn.red:not(:disabled) {
  color: #dc3545;
  border-color: #dc3545;
}

.ant-btn.red:not(:disabled):hover,
.ant-btn.red:not(:disabled):focus,
.ant-btn-primary.red:not(:disabled) {
  color: #fff;
  background: #dc3545;
  border-color: #dc3545;
}

.ant-btn-primary.red:not(:disabled):hover,
.ant-btn-primary.red:not(:disabled):focus {
  color: #fff;
  background: #c82333;
  border-color: #c82333;
}

.ant-btn.grey:not(:disabled) {
  color: #6c757d;
  border-color: #6c757d;
}

.ant-btn.grey:not(:disabled):hover,
.ant-btn.grey:not(:disabled):focus,
.ant-btn-primary.grey:not(:disabled) {
  color: #fff;
  background: #6c757d;
  border-color: #6c757d;
}

.ant-btn-primary.grey:not(:disabled):hover,
.ant-btn-primary.grey:not(:disabled):focus {
  color: #fff;
  background: #5a6268;
  border-color: #5a6268;
}

.main__wrap {
  padding: 15px 20px;
}

.no-gutters {
  margin: 0;
}

.disable_day {
  background: #f9e3e3 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-layout-sider {
  .ant-menu {
    font-size: 16px;
    background: transparent;
  }
}

.ant-form-item-label label {
  white-space: normal;
  height: auto;
}
.ant-menu-dark.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: rgba(0, 0, 0, 0.26);
}

/*
 * this styles don't apply on prod from antd so we duplicate they
*/
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}

.h-100 {
  height: 100%;
}

.clickable-table .ant-table-row {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.bg-white {
  background-color: @white;
}

.justify-center {
  justify-content: center;
}

.pt-0 {
  padding-top: 0 !important;
}

.flex-column {
  flex-direction: column;
}

.danger-text {
  color: @danger-color;
}

.primary-text {
  color: @primary-color !important;
}

.overflow-visible {
  overflow: visible !important;
}

.ant-row-base {
    align-items: baseline;
}
// чтобы при первой загрузке бэкгроунд страницы Auth был на весь экран
.ant-layout,
.ant-layout-content {
  height: 100%;
}
.ant-layout-header {
  background: @layout-header-background;
  color: @layout-header-color;
}
.ant-layout .ant-layout-sider {
  background: @layout-sider-background;
  height: 100vh;
}
