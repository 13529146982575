.Auth {
  .logo {
    width: 100%;
    height: 100vh;
    background: url('../../../img/logo@2x.png') no-repeat center;
    background-size: 350px, 100%;
  }
  .logo-wrapper {
    background-color: @orange-color;
  }
  .version {
    //position: absolute;
    top: calc(50% + 180px);
    right: calc(50% - 155px);
    //color: #fff;
    //font-size: 30px;
    //background: transparent;
  }
  .title {
    text-align: center;
    font-size: 32px;
    font-weight: normal;
    margin: 0;
  }
  .sub-title {
    margin: 45px auto 10px auto;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
  }
  input {
    height: 55px;
    font-size: 18px;
    //background-color: rgba(4, 161, 249, 0.19)!important;
  }
  input[type='password'] {
    height: 45px;
  }
  button {
    height: 55px;
    font-size: 25px;
    background-color: @orange-color;
    border-color: @orange-color;
  }
  .link {
    margin-top: 10px;
    text-align: center;
    a.danger {
      color: #d13135;
    }
  }
  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover,
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background: rgba(@orange-color, 0.8);
    border-color: rgba(@orange-color, 0.8);
  }
  .auth-input-margin {
    margin: 20px 0 0;
  }
}
@media only screen and (max-width: 575px) {
  .Auth .logo {
    height: 30vh;
  }
}
